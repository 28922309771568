import React, {useContext, useMemo, useState} from 'react';
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import parse from "html-react-parser";
import Image from "next/legacy/image";
import Link from 'next/link';
import nft_main_lights from '../../../public/banner/desktop_light_banner.png'
import nft_1 from '../../../public/banner/nft_1.png'
import nft_2 from '../../../public/banner/nft_2.png'
import nft_3 from '../../../public/banner/nft_3.png'
import nft_4 from '../../../public/banner/nft_4.png'
import upyo_top_img_light from '../../../public/banner/upyo-nft-light-m.png'
import nft_main_dark from '../../../public/banner/upyo-nft-dark.png'
import upyo_top_img_dark from '../../../public/banner/upyo-nft-dark-m.png'

// styles
import styles from "../Home2.module.scss";
import {useTranslation} from "next-i18next";
import {ThemeContext} from "../../../context/Context";
import ConstHelper from "../../../helper/constHelper";

// Swiper
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import {Autoplay, Navigation} from "swiper";


function HeaderSection({ content, currLocale }) {

    const { t } = useTranslation("common");
    const { currentTheme } = useContext(ThemeContext);
    const [windowWidth] = useState(typeof window !== 'undefined' && window.innerWidth);
    let themeImageSrcs, themeImageSrcSmallDevice, themeStyles;
    let direction = currLocale === 'ar' ? "rtl" : "ltr";

    if (currentTheme === ConstHelper.LIGHT_THEME){
        themeImageSrcs = nft_main_lights;
        themeImageSrcSmallDevice = upyo_top_img_light;
        themeStyles = styles.light;
    } else{
        themeImageSrcs = nft_main_dark;
        themeImageSrcSmallDevice = upyo_top_img_dark;
        themeStyles = styles.dark;
    }
    const isMobile = useMemo(()=>{
        return windowWidth && windowWidth < 575
    },[windowWidth])
    return (
        <>
            <div className={`position-relative ${styles.saleSlider}`}>
                <Swiper
                    dir={direction}
                    navigation={{
                        nextEl: ".swiper9-button-next",
                        prevEl: ".swiper9-button-prev",
                    }}
                    modules={[Navigation, Autoplay]}
                    className="p-b-1rem direCtions"
                    freeMode={true}
                    slidesPerView={1}
                    mousewheel={true}
                    grabCursor={true}
                    speed={1000}
                    autoHeight={true}
                    loop={true}
                    autoplay={{
                        delay: 5000,
                        pauseOnMouseEnter: true,
                        disableOnInteraction: false
                    }}
                    shouldSwiperUpdate={true}
                    rebuildOnUpdate={true}
                >
                    <SwiperSlide>
                        <section className="section">
                            <Container>
                                <Row className="align-items-center">
                                    <Col lg={6}>
                                        <div className={styles.mainHead}>
                                            <h1 className={`h2 mainTitle ${styles.mainTitle}`}>
                                                {content?.pageHeading ? content.pageHeading : t('common:hero_title')}
                                            </h1>
                                            <div className={styles.desc}>
                                                {content?.pageContent ? parse(content.pageContent) : t('common:hero_desc')}
                                            </div>
                                            <div className="d-flex flex-column flex-sm-row justify-content-center justify-content-md-start">
                                                <Link href="/nft/category"
                                                      className="btn">
                                                    {t('common:explore')}
                                                </Link>
                                                <Link href="/nft/upload-variants"
                                                      className="btn buttonStroke mt-sm-0 ms-sm-3">
                                                    {t('common:create')}
                                                </Link>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={6}>
                                        <div className={`position-relative ${styles.upyo_top_img}`}>
                                            {isMobile ?
                                                <div className={`light ${themeStyles}`}>
                                                    <Image src={themeImageSrcSmallDevice}
                                                           alt="UPYO NFT Marketplace"
                                                           width={544} height={384}
                                                           layout="responsive"
                                                           fetchpriority="high"
                                                           priority />
                                                </div>
                                            :
                                                <div className={`light ${themeStyles}`}>
                                                    <Image src={themeImageSrcs}
                                                           alt="UPYO NFT Marketplace"
                                                           width={544} height={384}
                                                           layout="responsive"
                                                           fetchpriority="high"
                                                           priority />
                                                    <div className={styles.sub_img}>
                                                        <div className={styles.nft_1}>
                                                            <Image src={nft_1}
                                                                   alt="UPYO NFT Marketplace"
                                                                   width={37} height={47}
                                                                   layout="responsive"
                                                                   loading="lazy" />
                                                        </div>
                                                        <div className={styles.nft_2}>
                                                            <Image src={nft_2}
                                                                   alt="UPYO NFT Marketplace"
                                                                   width={47} height={41}
                                                                   layout="responsive"
                                                                   loading="lazy" />
                                                        </div>
                                                        <div className={styles.nft_3}>
                                                            <Image src={nft_3}
                                                                   alt="UPYO NFT Marketplace"
                                                                   width={47} height={79}
                                                                   layout="responsive"
                                                                   loading="lazy" />
                                                        </div>
                                                        <div className={styles.nft_4}>
                                                            <Image src={nft_4}
                                                                   alt="UPYO NFT Marketplace"
                                                                   width={43} height={95}
                                                                   layout="responsive"
                                                                   loading="lazy" />
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </section>
                    </SwiperSlide>
                </Swiper>
                <Container className={styles.arrows}>
                </Container>
            </div>
        </>
    );
}

export default HeaderSection;